<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left"><span class="d-none d-sm-block">Refunds Transaction </span></h4> 
            <!-- <a href="/#/user/add" class="float-right btn btn-warning form-control-sm" style="margin-right: 15px;">Deposit</a> -->
            <!-- <a href="/#/user/add" class="float-right btn btn-info form-control-sm"  style="margin-right: 15px;">Withdraw</a> -->
            <!-- <div v-if="level === 'admin'">
              <button type="button"  @click="modalDepo2 = true;type_transaction=1" class="float-right btn btn-warning form-control-sm" >Deposit</button>
              <button type="button"  @click="modalDeporemove2 = true;type_transaction=1" class="float-right btn btn-danger form-control-sm" style="margin-right: 15px;">Reduce Balance</button>
            </div>
            <div v-if="level != 'admin'">
              <button type="button"  @click="modalDepo = true;type_transaction=1" class="float-right btn btn-warning form-control-sm">Deposit</button>
              <button type="button"  @click="modalWD = true;type_transaction=2" class="float-right btn btn-info form-control-sm" style="margin-right: 15px;">Withdrawal</button>
            </div> -->
          </slot>
        </CCardHeader>
        <CCardBody>

        
        <div v-if="itemlengths>0">
        <div class="form-group" v-show='pencarian'>
          <CInput
              placeholder="Cari Transaksi..."
              v-model="cari"
              v-on:keyup="loadSearch($event)"
            >
              <template #append-content> <CIcon name="cil-magnifying-glass"/></template>
            </CInput>
        </div>

        <CCard accent-color="secondary" v-for="row in items" :key="row.no">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-left"><strong>Refund</strong> ({{row.member_email}})<br><span v-html="row.payment_status"></span> </div>
              </CCol>
              <CCol>
                <div class="text-right"><strong><span class='text-info'>{{row.amount_transaction}}</span></strong><br><span class='text-secondary'>{{row.payment_date}}</span></div>
              </CCol>
            </CRow>
            <div v-if="row.link != ''" v-html="row.link"></div>
            <!-- <div > {{row.link}}</div> -->
          </CCardBody>
        </CCard>
        <CButton v-show='pencarian' block color="light" @click="loadMore()">Load More....</CButton>
        </div>



        <CCard accent-color="danger" v-if="itemlengths==0">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-center"><strong>EMPTY TRANSACTIONS. </strong></div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      
       <!--  <table class="table" id="datatable">
          <thead>
            <tr>
              <th style="width:130px">#</th>
              <th>Member </th>
              <th>Amount </th>
              <th>Method </th>
              <th>Status </th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  --> 


    <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>

    </CCardBody>
  </CCard>  

  <CModal title="Failed" :show.sync="myEmpty" size="sm">
      Failed! Please choose amount deposit...
  </CModal>
  <CModal title="Failed" :show.sync="myError" size="sm">
    Failed! Your deposit request is failed, please check your connection...
  </CModal>
  <CModal title="Failed" :show.sync="myErrorw" size="sm">
    Failed! Your withdrawal request is failed, please check your connection...
  </CModal>
  <CModal title="Failed" :show.sync="myErrorw2" size="sm">
    Failed! Please input valid address...
  </CModal>
  <CModal title="Success" :show.sync="mySuccess" size="sm">
    Success! You have request access for deposits to BlockScan. Please completed your payment!
  </CModal>

  <CModal title="Success" :show.sync="mySuccessw" size="sm">
    Success! You have request for withdrawal in BlockScan. Please wait we will verify your request.
  </CModal>

  <CModal
      title="Add Deposit"
      color="dark"
      id="modalDepo"      
      :show.sync="modalDepo"
    >
    <h5 class="text-center">Your Current Wallet : {{wallet}} USDT</h5>
    <hr>
      <div class="form-group">
        <label>Amount Depo</label>
        <input type="number" class="form-control" v-model="amountd" name="">
      </div>
    <input type="hidden" v-model="amountd">
    <template #footer>
        <h5 class="float-left">You Deposit Amount : {{amountd}} USDT</h5>
        <CButton @click="modalDepo = false" color="light">Cancel</CButton>
        <CButton @click="depoNow" color="dark">Process</CButton>
    </template>
    </CModal>
    <CModal
      title="Add Deposit"
      color="dark"
      id="modalDepo2"      
      :show.sync="modalDepo2"
    >
      <div class="form-group">
        <label>Amount Depo</label>
        <input type="number" class="form-control" v-model="amountd" name="">
      </div>
      <div class="form-group">
        <label>Member</label>
        <!-- <select class="form-control" name="select2" v-model="mmid"
                 language="en-US">
                 <option v-for="m in members" :value="m.value">{{m.text}}</option>
        </select> -->
        <model-select :options="members"
                                v-model="mmid"
                                @change="onSelected(mmid)"
                                placeholder="select member">
         </model-select>
      </div>
      <!-- 
          :options="[{ id: 1, name: 'Option 1'}, { id: 2, name: 'Option 2'}]" --->
      <div class="form-group">
        <!-- <Dropdown
          :options=options2
          v-on:filter="getDropdownValues"
          :disabled="false"
          name="zipcode"
          v-model="cariS"
          :maxItem="30"
          placeholder="Please select an option">
      </Dropdown> -->
      </div>
    <input type="hidden" v-model="amountd">
    <template #footer>
        <h5 class="float-left">You Deposit Amount : {{amountd}} USDT</h5>
        <CButton @click="modalDepo2 = false" color="light">Cancel</CButton>
        <CButton @click="depoNow2" color="dark">Process</CButton>
    </template>
    </CModal>

    <CModal
      title="Reduce Balance"
      color="dark"
      id="modalDepo2"      
      :show.sync="modalDeporemove2"
    >
      <div class="form-group">
        <label>Amount Reduce</label>
        <input type="number" class="form-control" v-model="amountdr" name="">
      </div>
      <div class="form-group">
        <label>Member</label>
        
        <model-select :options="members"
                                v-model="mmidr"
                                @change="onSelected(mmidr)"
                                placeholder="select member">
         </model-select>
        <!-- <select class="form-control" name="select2" v-model="mmidr"
                 language="en-US">
                 <option v-for="m in members" :value="m.value">{{m.text}}</option>
        </select> -->
      </div>
      
      <!-- <div class="form-group">
      </div> -->
    <input type="hidden" v-model="amountdr">
    <template #footer>
        <h5 class="float-left">Reduce Balance, Amount : {{amountdr}} USDT</h5>
        <CButton @click="modalDeporemove2 = false" color="light">Cancel</CButton>
        <CButton @click="removeDepo" color="dark">Process</CButton>
    </template>
    </CModal>

    <CModal
      title="New Withdrawal"
      color="dark"
      id="modalWD"      
      :show.sync="modalWD"
    >
    <h5 class="text-center">Your Current Wallet : {{wallet}} USDT</h5>
    <hr>
      <div class="form-group">
        <label>Amount Withdrawal</label>
        <input type="text" class="form-control" v-model="amount_w" name="">
      </div>
      <div class="form-group">
        <label>Your Email</label>
        <input type="text" class="form-control"  v-model="email_w" name="">
      </div>
      <div class="form-group">
        <label>Your Wallet Address</label>
        <input type="text" class="form-control" v-model="address_w" :readonly="isAddress" name="">
      </div>
    <input type="hidden" v-model="amount_w">
    <template #footer>
        <h5 class="float-left">You Withdraw Amount : {{amount_w}} USDT</h5>
        <CButton @click="modalWD = false" color="light">Cancel</CButton>
        <CButton @click="wdNow" color="dark">Process</CButton>
    </template>
      
      
    </CModal>
    <div style="position:fixed;left: 50%;top:50%">
      <b-spinner variant="danger" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import { ModelSelect } from 'vue-search-select';
import $ from 'jquery'; 



let user = JSON.parse(localStorage.getItem('user'));

// let us;
export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  components: {
    ModelSelect
  },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    this.loadData();
    this.timer = setInterval(this.loadData(), 60000);
      let us = JSON.parse(this.$store.state.users);
    this.address_w = this.usr.wallet_address;
    // if(this.address_w){
    //   this.isAddress = true;
    // }
    // else{
    //   this.isAddress = false;
    // }
    // alert(this.address_w);
      if(this.usr.level=='1'){
        this.pencarian = true;
        this.level ='admin';
      }
      else{
        this.level ='user'
      }
      this.idm = this.usr.id_member;
      this.email_w = this.usr.id ;
      this.mid = this.usr.id_member;
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  },
  data: function() {
        return {
            level:"user",
            usr :JSON.parse(this.$store.state.users),
            products:[],
            options2:[],
            pencarian : false,
            cari:'',
            cariS:'',
            cariS2:'',
            idm :0,
            members:[],
            items:[],
            length:200,
            start:0,
            itemlengths:0,
            loading:false,
            amount_w:0,
            timer :"",
            type_transaction : 1,
            tt:"adddepo",
            depo : 0,
            isAddress : false,
            address_w:"",
            email_w:"",
            modalDepo:false,
            modalDepo2:false,
            modalDeporemove2:false,
            modalWD:false,
            mySpinner : false,
            myError : false,
            myErrorw : false,
            myErrorw2 : false,
            mySuccess:false,
            mySuccessw:false,
            myEmpty : false,
            mid:"0",
            mmid:0,
            mmidr:0,
            wallet:"0",
            amountd:0,
            amountdr:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    onSelected: function(val){
      alert(val);
    },
    loadMore: function() {
      this.length = this.length + 100;
      // this.start = this.start + 100;
      this.loadData();
    },
    loadSearch: function(event) {
      var key = event.keyCode || event.charCode;
      if( key == 8 ){
        //backspace pressed
        this.loadData();  
      }
      if(this.cari.length>2){
        this.loadData();  
      }
      
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    },
    addAmount: function(ad) {
      this.amountd = ad;
      // alert(ad);
    },
    depoNow: function (event) {
      this.mySpinner = true;

      // Simple POST request with a JSON body using axios
      this.modalStake = false;
      const stk = {
        email: this.usr.id,
        mid: this.mid,
        amount_d: this.amountd,
      };
      // alert(JSON.stringify(stk));
      if((this.amountd == null || this.amountd == "0")) {
        this.myEmpty = true;
      }
      else{
        if(this.type_transaction==2){
          this.tt = "addwithdrawal";
        }else{
          this.tt = "adddepo";
        }
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/"+this.tt+"/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
                this.mySpinner = false;
            if (response.data.error == "ok") {
              if(this.type_transaction==2){
                this.modalWD =false;
              }else{
                this.modalDepo =false;
              }
              
              let idx = response.data.result.txn_id;
              // alert(idx);
              // if (window.confirm('Success. You are now redirecting to de'))
              // {
              //     // They clicked Yes
              // }
              // else
              // {
              //     // They clicked no
              // }
              // alert(idx);
              if(idx!=''){
                this.mySpinner = false;
                if(this.type_transaction==2){
                  this.loadData;
                }else{
                  this.$router.push("/deposits/detail/"+idx);  
                }
                
              }
              else{
                this.mySpinner = false;
                this.myError = true;
              }
              

              this.mySuccess = true;
            } else {
              if(this.type_transaction==2){
                this.modalWD =false;
              }else{
                this.modalDepo =false;
              }
              this.myError = true;
            }
          });
      }
    },
    wdNow: function (event) {
      this.modalWD = false;
      this.mySpinner = true;
      // Simple POST request with a JSON body using axios
      const stk = {
        email: this.usr.id,
        mid: this.mid,
        address_w: this.address_w,
        amount_w: this.amount_w,
      };
      // alert(JSON.stringify(stk));
      if((this.amount_w == null || this.amount_w == "0" || this.amount_w < 6|| this.amount_w > this.wallet)) {
        this.modalWD = false;
        this.myEmpty = true;
        this.mySpinner = false;
        // alert('bisa');
      }
      else{
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/addwithdrawal/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
            this.mySpinner = false;
            if (response.data.error == "ok") {
                this.modalWD =false;
            this.loadData;
            this.mySuccessw = true;
            user.wallet_address =this.address_w;
            this.isAddress = true;
            localStorage.setItem("user", JSON.stringify(user));
            // alert(user.wallet_address);
                
               
              

            } else {
              if(response.data.error="That is not a valid address for that coin!"){
                this.modalWD =false;
                this.myErrorw2 = true;
              }
              else{
                this.modalWD =false;
                this.myErrorw = true;
              }
              
            }
          });
      }
    },
    depoNow2: function (event) {
      // Simple POST request with a JSON body using axios
      this.modalDepo2 = false;
      this.mySpinner = true;
      const stk = {
        email: this.usr.id,
        mid: this.mmid,
        amount_d: this.amountd,
      };
      // alert(JSON.stringify(stk));
      if((this.amountd == null || this.amountd == "0")) {
        this.myEmpty = true;
      }
      else{
        if(this.type_transaction==2){
          this.tt = "addwithdrawal";
        }else{
          this.tt = "adddepo";
        }
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/adddepo2/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
                this.mySpinner = false;
            if (response.data == "ok") {
                this.modalDepo2 =false;
            
              // if(idx!=''){
                this.loadData();
              //   this.$router.push("/deposits/detail/"+idx);  
              // }
              // else{
              //   this.myError = true;
              // }
              

              this.mySuccess = true;
            } else {
                this.modalDepo =false;
              
              this.myError = true;
            }
          });
      }
    },
    removeDepo: function (event) {
      this.mySpinner = true;
      // Simple POST request with a JSON body using axios
      this.modalDeporemove2 = false;
      const stk = {
        email: this.usr.id,
        mid: this.mmidr,
        amount_d: this.amountdr,
      };
      // alert(JSON.stringify(stk));
      if((this.amountdr == null || this.amountdr == "0")) {
        this.myEmpty = true;
      }
      else{
        // alert(this.tt);
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/removedepo/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
                this.mySpinner = false;
            if (response.data == "ok") {
                this.modalDeporemove2 =false;
            
              // if(idx!=''){
                this.loadData();
              //   this.$router.push("/deposits/detail/"+idx);  
              // }
              // else{
              //   this.myError = true;
              // }
              

              this.mySuccess = true;
            } else {
                this.modalDepo =false;
              
              this.myError = true;
            }
          });
      }
    },
    updateData: function (event) {
      // alert(this.$route.params.txid);
      // Simple POST request with a JSON body using axios
      
      // alert(JSON.stringify(pekerjaan));
      axios
        .get(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/updatemultipayment/" +
            this.$route.params.trn +
            "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          // alert(response);
        });
    },     
    cariData: function() {
        axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/deposits/0/"+this.cari+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          this.items=response.data.data;
          this.itemlengths = this.items.length;

          // alert(JSON.stringify(this.items));
        });

    },
    getDropdownValues: function () {
      // var key = event.keyCode || event.charCode;
      console.log(this.cariS);
       if(this.cariS.length>2){
        axios
        .get(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/mmbr/"+this.cariS+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          // alert(response);
          var mmbrs = response.data;
          var obj = {};
          mmbrs.forEach(element => {
             obj['id'] = element.value; 
             obj['name'] = element.text;
             this.options2.push(obj); 
             
          });
          console.log("==Members==",this.options2);
          // this.options2 = response.data;

          // alert(this.members);
        });
      }
      
    },

    getDropdownValues2: function (event) {
      // var key = event.keyCode || event.charCode;
      console.log("cariS2"+event.target.value);
       if(this.cariS2.length>2){
        axios
        .get(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/mmbr/"+this.cariS2+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          // alert(response);
          var mmbrs = response.data;
          var obj = {};
          mmbrs.forEach(element => {
             obj['id'] = element.value; 
             obj['name'] = element.text;
             this.options2.push(obj); 
             
          });
          console.log("==Members==",this.options2);
          // this.options2 = response.data;

          // alert(this.members);
        });
      }
      
    },
    loadData: function() {
      axios
        .get(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/mmbr/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          // alert(response);
          this.members = response.data;
          // var obj = {};
          // this.members.forEach(element => {
          //    obj['id'] = element.value; 
          //    obj['name'] = element.text;
          //    this.options2.push(obj); 
             
          // });
          // console.log("==Members==",this.options2);
          
        });
      let id_member = this.mid;
          let idm;
          if(id_member=="0") {
            idm = "";
          }
          else{
            idm = id_member;
          }

        const search = { 
          cari: this.cari, 
          length : this.length,
          start : this.start,
          };
        axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/refunds/"+this.usr.id_member+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",search
        )
        .then((response) => {
          this.items = [];
          this.items=response.data.data;
          this.itemlengths = this.items.length;

          // alert(JSON.stringify(this.items));
        });

          axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/dashboardstat/"+this.usr.id_member+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.wallet = parseFloat(response.data.wallet);
                this.depo = parseFloat(response.data.depo);
                var myObj = {
                  "wallet" : response.data.wallet,    //your artist variable
                };
                this.usr.wallet=response.data.wallet;
            });
     
      // if (this.tahun == null){

      //     $('#datatable').DataTable({
      //       "processing": true,
      //         "serverSide": true,
      //          "pageLength": 50,
      //         "ajax":{
      //                  "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/deposits/"+idm+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
      //                  "dataType": "json",
      //                  "type": "POST"
      //                },
      //         "columns": [
      //             { "data": "payment_date",className: "text-center" },
      //             { "data": "member_name" },
      //             { "data": "amount_transaction", className: "text-center" },
      //             { "data": "payment_method", className: "text-center" },
      //             { "data": "payment_status", className: "text-center" },
      //             { "data": "detail" ,className: "text-center" },
      //         ]  

      //     });
          
      //     $.fn.dataTableExt.sErrMode = 'throw';
          
      //   // })
      // }
      // else{
      //     $('#datatable').DataTable({
      //                 "processing": true,
      //         "serverSide": true,
      //          "pageLength": 50,
      //         "ajax":{
      //                  "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/deposits/"+idm+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
      //                  "dataType": "json",
      //                  "type": "POST"
      //                },
      //         "columns": [
      //             { "data": "payment_date",className: "text-center" },
      //             { "data": "member_name" },
      //             { "data": "amount_transaction", className: "text-center" },
      //             { "data": "payment_method", className: "text-center" },
      //             { "data": "payment_status", className: "text-center" },
      //             { "data": "detail" ,className: "text-center" },
      //         ]  
          
 
      //     });
          
      //     $.fn.dataTableExt.sErrMode = 'throw';
          
      // }
      
    }
  }
}
</script>